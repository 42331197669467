:root {
  --blue: #006fff;
}

:focus-visible {
  outline-width: 1px;
  z-index: 1;
}

.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

.z-index {
  z-index: 1;
}
.z-999 {
  z-index: 999;
}

.right {
  right: 0;
}
.bottom {
  bottom: 0;
}
.top {
  top: 0;
}

.width-100 {
  width: 100% !important;
}
.width-100vw {
  width: 100vw;
}
.width-7px {
  width: 7px;
}
.width-8px {
  width: 8px;
}
.width-20px {
  width: 20px;
}
.width-24px {
  width: 24px;
}
.width-32 {
  width: 32px;
}
.width-80 {
  width: 80px;
}
.width-142px {
  width: 142px;
}
.width-280px {
  width: 280px;
}
.width-400px {
  width: 400px;
}
.width-420px {
  width: 420px;
}
.max-width-520 {
  max-width: 520px;
}
.max-width-980 {
  max-width: 980px;
}

.height-100,
.full-height {
  height: 100%;
}
.height-100vh {
  height: 100vh;
}
.height-px {
  height: 1px;
}
.height-7px {
  height: 7px;
}
.height-8px {
  height: 8px;
}
.height-20px {
  height: 20px;
}
.height-32px {
  height: 32px;
}
.height-34px {
  height: 34px;
}
.height-36px {
  height: 36px;
}

.flex-min-height-34px {
  flex: 1 0 34px;
}
.min-height-140 {
  min-height: 140px;
}
.min-height-230 {
  min-height: 230px;
}

.max-height-150 {
  max-height: 150px;
}

.block {
  display: block;
}
.hide {
  display: none;
}

.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column,
.column {
  flex-direction: column;
}
.flex-1 {
  flex: 1;
}
.flex-1-children > * {
  flex: 1;
}
.flex-50 {
  flex: 0 0 50%;
}
.flex-100 {
  flex: 0 0 100%;
}
.flex-none {
  flex: none;
}
.flex-basis,
.flex-basis-0 {
  flex-basis: 0;
}
.flex-grow {
  flex-grow: 1;
}

.justify-left {
  justify-content: left !important;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
.space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.split > * {
  width: initial !important;
  max-width: 50%;
}

.grid-column {
  display: grid;
  grid-template-columns: 1fr;
}

.p-0 {
  padding: 0 !important;
}
.p-2 {
  padding: 2px;
}
.p-4 {
  padding: 4px;
}
.p-8 {
  padding: 8px;
}
.p-10 {
  padding: 10px;
}
.p-12 {
  padding: 12px;
}
.p-16 {
  padding: 16px;
}
.p-20 {
  padding: 20px;
}

.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}
.px-6 {
  padding-left: 6px;
  padding-right: 6px;
}
.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.px-56 {
  padding-left: 56px;
  padding-right: 56px;
}

.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.py-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-28 {
  padding-top: 28px;
  padding-bottom: 28px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pl-2 {
  padding-left: 2px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-24 {
  padding-left: 24px;
}

.pt-12 {
  padding-top: 12px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-24 {
  padding-top: 24px;
}
.pt-56 {
  padding-top: 56px;
}

.pb-8 {
  padding-bottom: 8px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-40 {
  padding-bottom: 40px;
}

.m-5 {
  margin: 5px;
}
.m-6 {
  margin: 6px;
}
.m-10 {
  margin: 10px;
}
.m-20 {
  margin: 20px;
}
.m-32 {
  margin: 32px;
}

.m-0 {
  margin: 0;
}
.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.mx-6 {
  margin-left: 6px;
  margin-right: 6px;
}
.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.my-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}
.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-14 {
  margin-top: 14px;
  margin-bottom: 14px;
}
.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mt-4 {
  margin-top: 4px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-32 {
  margin-top: 32px;
}

.ml-4 {
  margin-left: 4px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-16 {
  margin-left: 16px;
}

.mr-4 {
  margin-right: 4px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-34 {
  margin-right: 34px;
}

.mb-4 {
  margin-bottom: 4px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-64 {
  margin-bottom: 64px;
}

.s-1 > * {
  margin-bottom: 1px;
}
.s-1 > *:last-child {
  margin-bottom: 0;
}
.s-4 > * {
  margin-bottom: 4px;
}
.s-4 > *:last-child {
  margin-bottom: 0;
}
.s-8 > * {
  margin-bottom: 8px;
}
.s-8 > *:last-child {
  margin-bottom: 0;
}
.s-12 > * {
  margin-bottom: 12px;
}
.s-12 > *:last-child {
  margin-bottom: 0;
}
.s-16 {
  display: grid;
  gap: 16px;
}
.s-24 {
  display: grid;
  gap: 24px;
}

.sr-40 > * {
  margin-right: 40px;
}
.sr-40 > *:last-child {
  margin-right: 0;
}

.gap-2 {
  gap: 2px;
}
.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}
.gap-20 {
  gap: 20px;
}
.gap-24 {
  gap: 24px;
}

.bg-white {
  background-color: #fff;
}
.bg-blue {
  background: #006fff;
}
.bg-gray,
.hover\:bg-gray:hover {
  background-color: #f9fafa;
}
.bg-dark-gray {
  background-color: rgba(34, 34, 37, 0.06);
}
.bg-light-blue {
  background-color: #e5f1ff;
}
.bg-lighter-blue {
  background-color: #f9f9fc;
}
.bg-neutral {
  background: #c2c6cb;
}
.bg-uicare-light {
  background: #006fff0d;
}

.pre {
  white-space: pre;
}

.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}

.line-height {
  line-height: 20px;
}
.line-48 {
  line-height: 48px;
}

.color-dark {
  color: rgba(255, 255, 255, 0.65);
}
.color-blue,
.color-primary,
.color-link {
  color: #006fff;
}
.color-danger {
  color: #f03a3e;
}

.opacity-40 {
  opacity: 0.4;
}

.wrap-pretty {
  text-wrap: pretty;
}
.pre-wrap {
  white-space: pre-wrap;
}

.break-word {
  word-break: break-word;
}
.wrap-break-word {
  word-wrap: break-word;
}
.break-all {
  word-wrap: break-all;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-right {
  text-align: right;
}

.border-top {
  border-top: 1px solid #eeeef1;
}
.border-right {
  border-right: 1px solid #eeeef1;
}
.border-bottom {
  border-bottom: 1px solid #eeeef1;
}

.border-bl-radius-4 {
  border-bottom-left-radius: 4px;
}
.border-br-radius-4 {
  border-bottom-right-radius: 4px;
}
.border-radius-4 {
  border-radius: 4px;
}
.border-radius-7 {
  border-radius: 7px;
}
.border-radius,
.border-radius-8 {
  border-radius: 8px;
}
.border-radius-circle {
  border-radius: 50%;
}
.focus\:outline:focus,
.hover\:outline:hover {
  outline: 1px solid var(--blue);
}

.border {
  border: 1px solid rgba(229, 231, 236, 1);
}
.border-blue {
  border: 1px solid #006fff;
}

.overflow {
  overflow: auto;
}
.scroll-x {
  overflow-x: scroll;
}
.scroll-snap-x {
  scroll-snap-type: x mandatory;
}
.scroll-snap-x > * {
  scroll-snap-align: center;
}
.scroll-smooth {
  scroll-behavior: smooth;
}

.scroll-y {
  overflow-y: scroll;
}

.scroll-hide::-webkit-scrollbar {
  width: 0 !important;
}
.scroll-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.overflow {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}

.pointer {
  cursor: pointer;
}
.pointer-events-none {
  pointer-events: none;
}

.resize-none {
  resize: none;
}

@media (min-width: 520px) {
  .sm\:row {
    flex-direction: row;
  }
  .sm\:row-reverse {
    flex-direction: row-reverse;
  }
  .sm\:align-center {
    align-items: center;
  }

  .sm\:sl-4 > * {
    margin-left: 4px;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:max-width-276px {
    max-width: 276px;
  }
}

@media (min-width: 980px) {
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:px-68 {
    padding-left: 68px;
    padding-right: 68px;
  }
  .lg\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:border-radius {
    border-radius: 8px;
  }

  .lg\:hide {
    display: none;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

@media (max-width: 980px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

.hide-thead thead {
  display: none !important;
}

.box-shadow {
  box-shadow: 0px 14px 48px rgba(0, 0, 0, 0.1);
}

.hover-gray:hover {
  background-color: rgb(248, 248, 249);
}
.active-gray {
  background-color: #f1f1f1;
}
